import {
  SET_SEND_DATA_VIRTUAL_STORE,
  SET_VIEW_SINGLE_VIRTUAL_STORE,
  SET_VIEW_SINGLE_VIRTUAL_STORE_PRODUCT,
} from "../actionTypes";

const initialState = {
  singleData: {},
  sendData: {},
  productsData: [],
};

function crudVirtualStoreReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_VIEW_SINGLE_VIRTUAL_STORE:
      return { ...state, singleData: payload };
    case SET_SEND_DATA_VIRTUAL_STORE:
      return { ...state, sendData: payload };
    case SET_VIEW_SINGLE_VIRTUAL_STORE_PRODUCT:
      return { ...state, productsData: payload };
    default:
      return state;
  }
}

export default crudVirtualStoreReducer;
