import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./store";

// Login CMS
import Dashboard from "./components/dashboard";
import Login from "./components/auth/login";
import { ErrorBoundary } from "react-error-boundary";
import loggingError from "./services/loggingError";
import FallbackErrorPage from "./components/error/FallbackErrorPage";

import LoginProvider from "./components/LoginProvider";

// Login Virtual Store
import VirtualStoreLogin from "./components/virtualStore/virtualStoreLogin";
import ListDownline from "./components/downlineManagement/listDownline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import EditDownline from "./components/downlineManagement/editDownline";

// components
const AllProducts = lazy(() => import("./components/products/AllProducts"));
const ListPromo = lazy(() =>
  import("./components/promo/List Promo/list-promo")
);
const CreatePromo = lazy(() =>
  import("./components/promo/Create Promo/createPromo")
);
const EditPromo = lazy(() =>
  import("./components/promo/Edit Promo/edit-promo")
);
const PromoUsage = lazy(() => import("./components/promo/Promo Usage"));

const ListProductShowcase = lazy(() =>
  import("./components/showcase/list-product-showcase")
);
const CreateProductShowcase = lazy(() =>
  import("./components/showcase/create-showcase")
);
const EditProductShowcase = lazy(() =>
  import("./components/showcase/edit-showcase")
);
const ListgrowthPoint = lazy(() =>
  import("./components/growthPoint/list-growthPoint")
);
const DetailgrowthPoint = lazy(() =>
  import("./components/growthPoint/detail-growthPoint")
);
const AddNewProduct = lazy(() => import("./components/products/AddNewProduct"));
const Categories = lazy(() => import("./components/products/Categories"));
const EditCategory = lazy(() => import("./components/products/EditCategory"));
const ListWarehouse = lazy(() =>
  import("./components/warehouse/list-warehouse")
);
const CreateWarehouse = lazy(() =>
  import("./components/warehouse/create-warehouse")
);
const EditWareHouse = lazy(() =>
  import("./components/warehouse/edit-warehouse")
);
const StockList = lazy(() => import("./components/warehouse/stock-list"));
const HomeBanner = lazy(() => import("./components/content/home-banner"));
const HighlightBanner = lazy(() =>
  import("./components/content/highlight-banner")
);
const LandingBanner = lazy(() => import("./components/content/landing-banner"));
const ResellerBanner = lazy(() =>
  import("./components/content/reseller-banner")
);
const Testimony = lazy(() => import("./components/content/testimony"));
const OrderDetail = lazy(() => import("./components/order"));
const ListOrder = lazy(() => import("./components/order/list-order"));
const ProductPackage = lazy(() =>
  import("./components/products/ProductPackage")
);
const ProductReviewsDetail = lazy(() =>
  import("./components/products/component/ProductReviewsDetail")
);
const ListCommission = lazy(() =>
  import("./components/commission/list-commission")
);
const ListCommissionPayout = lazy(() =>
  import("./components/commission/list-commission-payout")
);
const DetailCommission = lazy(() =>
  import("./components/commission/detail-commission")
);
const ProductTagging = lazy(() => import("./components/products/Tagging"));
const ListUser = lazy(() => import("./components/users/list-user"));
const CreateUser = lazy(() => import("./components/users/create-user"));
const ListCommunity = lazy(() =>
  import("./components/community/list-community")
);
const ListSuggestion = lazy(() =>
  import("./components/community/list-suggestion")
);
const Feedback = lazy(() => import("./components/helpCenter/feedback"));
const EditUser = lazy(() => import("./components/users/edit-user"));
const ListArticle = lazy(() => import("./components/helpCenter/list-article"));
const AddArticle = lazy(() => import("./components/helpCenter/AddArticle"));
const Subtopic = lazy(() => import("./components/helpCenter/subtopic"));
const ArticleFeedback = lazy(() =>
  import("./components/helpCenter/ArticleFeedback")
);
const GeneralFaq = lazy(() => import("./components/helpCenter/listFaqs"));
const DetailCommissionPayout = lazy(() =>
  import("./components/commission/detail-commission-payout")
);
const ListAdmin = lazy(() => import("./components/admin/ListAdmin"));
const ListCampaign = lazy(() => import("./components/campaign/ListCampaign"));
const AddCampaign = lazy(() => import("./components/campaign/AddCampaign"));
const ListMetadata = lazy(() => import("./components/metadata/ListMetadata"));
const AddMetadata = lazy(() => import("./components/metadata/AddMetadata"));
const EditMetadata = lazy(() => import("./components/metadata/EditMetaData"));
const ListRoles = lazy(() => import("./components/roles/ListRoles"));
const CreateRole = lazy(() => import("./components/roles/CreateRole"));
const EditRole = lazy(() => import("./components/roles/EditRole"));
const ListRedemption = lazy(() =>
  import("./components/redemptions/ListRedemption")
);
const ListResellerLevelManagement = lazy(() =>
  import("./components/resellerLevelManagement/ListResellerLevelManagement")
);
const BeyondStarUser = lazy(() =>
  import("./components/users/beyond-star-user")
);
const VirtualStoreHome = lazy(() =>
  import("./components/virtual-store/dashboard")
);
const VirtualStockManagement = lazy(() =>
  import("./components/virtual-store/stock-management")
);
const ListPromotor = lazy(() =>
  import("./components/virtualStoreUser/listPromotor")
);
const ListVirtualStore = lazy(() =>
  import("./components/virtualStoreManagement/listVirtualStore")
);
const CruPromoStore = lazy(() =>
  import("./components/virtualStorePromo/createPromo/createPromo")
);
const CruStore = lazy(() =>
  import("./components/virtualStoreManagement/cruStore")
);
const ListPromoVirtualStore = lazy(() =>
  import("./components/virtualStorePromo/listPromoVirtualStore")
);
const EditPromoVirtualStore = lazy(() =>
  import("./components/virtualStorePromo/editPromo/editPromo")
);
const ListOrderVirtualStore = lazy(() =>
  import("./components/virtual-store/order-management/list-order")
);
const VirtualOrderDetail = lazy(() =>
  import("./components/virtual-store/order-management")
);

const theme = extendTheme({
  components: {
    Tooltip: {
      variants: {
        white: {
          bg: "white",
          color: "black",
          padding: "16px",
          borderRadius: "4px",
        },
      },
    },
  },
});

const queryClient = new QueryClient();

const Root = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary
          onError={loggingError}
          FallbackComponent={FallbackErrorPage}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter basename={"/"}>
              <LoginProvider>
                <PerfectScrollbar>
                  <Switch>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/virtual-store`}
                      component={VirtualStoreLogin}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={Login}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/auth/login`}
                      component={Login}
                    />
                    <App>
                      <Route
                        path={`${process.env.PUBLIC_URL}/dashboard`}
                        component={Dashboard}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/content/home-banner`}
                        component={HomeBanner}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/content/testimony`}
                        component={Testimony}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/content/highlight-banner`}
                        component={HighlightBanner}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/content/landing-banner`}
                        component={LandingBanner}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/content/reseller-banner`}
                        component={ResellerBanner}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/content/list-productshowcase`}
                        component={ListProductShowcase}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/content/create-productshowcase`}
                        component={CreateProductShowcase}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/content/edit-productshowcase`}
                        component={EditProductShowcase}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/products/all-products`}
                        component={AllProducts}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/products/tagging`}
                        component={ProductTagging}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/products/add-product`}
                        component={AddNewProduct}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/products/edit-product/:productId`}
                        component={AddNewProduct}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/products/categories`}
                        component={Categories}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/products/reviews-detail/:slug`}
                        component={ProductReviewsDetail}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/products/edit-category/:category`}
                        component={EditCategory}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/products/package-recommendation`}
                        component={ProductPackage}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/products/edit-sub-category/:subCategory`}
                        component={EditCategory}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/warehouse/create-warehouse`}
                        component={CreateWarehouse}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/warehouse/edit-warehouse/:id`}
                        component={EditWareHouse}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/warehouse/list-warehouse`}
                        component={ListWarehouse}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/warehouse/list-warehouse/:id`}
                        component={StockList}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/order-detail/:id`}
                        component={OrderDetail}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/order-list`}
                        component={ListOrder}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/promo/list-promo`}>
                        <ListPromo pathName="/promo/list-promo" />
                      </Route>
                      <Route
                        path={`${process.env.PUBLIC_URL}/promo/create-promo`}
                        component={CreatePromo}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/promo/edit-promo/:id`}
                        component={EditPromo}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/promo/promo-usage/:id`}
                        component={PromoUsage}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/growth-point/list-gp`}
                        component={ListgrowthPoint}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/growth-point/detail-gp/:id`}
                        component={DetailgrowthPoint}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/commission/list-commission`}
                        component={ListCommission}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/commission/detail-commission/:id`}
                        component={DetailCommission}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/commission/list-payout`}
                        component={ListCommissionPayout}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/commission/detail-commission-payout/:id`}
                        component={DetailCommissionPayout}
                      />

                      {/* User */}
                      <Route
                        path={`${process.env.PUBLIC_URL}/users/all-user`}
                        component={ListUser}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/users/beyond-star-user`}
                        component={BeyondStarUser}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/users/create-user`}
                        component={CreateUser}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/users/edit-user`}
                        component={EditUser}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/community/list-community`}
                        component={ListCommunity}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/community/list-suggestion`}
                        component={ListSuggestion}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/feedback`}
                        component={Feedback}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/article`}
                        component={ListArticle}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/add-article`}
                        component={AddArticle}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/edit-article/:id`}
                        component={AddArticle}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/subtopic`}
                        component={Subtopic}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/feedback-article/:id`}
                        component={ArticleFeedback}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/help-center/faq`}
                        component={GeneralFaq}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/admin/list-admin`}
                        component={ListAdmin}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/campaign/list-campaign`}
                        component={ListCampaign}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/campaign/add-campaign`}
                        component={AddCampaign}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/campaign/list-promo-campaign`}
                        component={ListCampaign}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/campaign/edit-campaign/:id`}
                        component={AddCampaign}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/metadata/list-metadata`}
                        component={ListMetadata}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/metadata/add-metadata`}
                        component={AddMetadata}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/metadata/edit-metadata`}
                        component={EditMetadata}
                      />
                      {/* CRUD Role Admin */}
                      <Route
                        path={`${process.env.PUBLIC_URL}/roles/view-roles`}
                        component={ListRoles}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/roles/create-roles`}
                        component={CreateRole}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/roles/edit-roles`}
                        component={EditRole}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/redemption/list-redemption`}
                        component={ListRedemption}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/reseller-level/list-reseller-level`}
                        component={ListResellerLevelManagement}
                      />
                      {/* Virtual Store */}
                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/dashboard`}
                        component={VirtualStoreHome}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/stock-management`}
                        component={VirtualStockManagement}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/promo/list-promo`}>
                        <ListPromoVirtualStore pathName="/virtual-store/promo/list-promo" />
                      </Route>
                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/promo/edit-promo/:id`}
                        component={EditPromoVirtualStore}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store-user/list-promotor`}
                        component={ListPromotor}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/list-virtual`}
                        component={ListVirtualStore}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/create-virtual`}>
                        <CruStore type="create" />
                      </Route>

                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/view-virtual/:id`}>
                        <CruStore type="view" />
                      </Route>

                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/edit-virtual/:id`}>
                        <CruStore type="edit" />
                      </Route>

                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/promo/create-promo`}
                        component={CruPromoStore}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/order-list`}
                        component={ListOrderVirtualStore}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/virtual-store/order-detail/:id`}
                        component={VirtualOrderDetail}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/downline-management/list-downline`}
                        component={ListDownline}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/downline-management/edit-downline/:code`}
                        component={EditDownline}
                      />
                      <script
                        src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_QBEE_SITE_KEY}`}
                        async
                        defer></script>
                    </App>
                  </Switch>
                </PerfectScrollbar>
              </LoginProvider>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.error))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
