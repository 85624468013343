import {
    Box,
    Spinner,
  } from '@chakra-ui/react';
  import React from 'react';
  
  const Loader = () => (
    <Box
      width="100vw"
      height="100vh"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,0.64)',
        zIndex: 1500,
      }}
    >
      <Spinner
        size="xl"
        color="white"
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          zIndex: 11,
        }}
      />
    </Box>
  );
  
  export default Loader;
  