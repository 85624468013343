import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import Axios from "../../services/axios";
import { load } from "recaptcha-v3";

const LoginTabset = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };
  const toast = useToast();

  const routeChange = () => {
    history.push(`${process.env.PUBLIC_URL}/dashboard`);
  };

  const handleValidInput = () => {
    let statusValidation = {
      status: "success",
      message: "Berhasil melakukan login.",
    };

    if (!email) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi email dahulu.";
    } else if (!password) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi password dahulu.";
    }

    return statusValidation;
  };

  const loadCaptcha = async () => {
    const recaptcha = await load(process.env.REACT_APP_QBEE_SITE_KEY);
    const token = await recaptcha.execute(process.env.REACT_APP_QBEE_SITE_KEY, {
      action: "submit",
    });

    return token;
  };

  const handleLogin = () => {
    let validation = handleValidInput();
    loadCaptcha().then(async (res) => {
      if (validation.status === "success") {
        try {
          const { data } = await Axios({
            url: "/admin/auth",
            method: "POST",
            data: {
              email,
              password,
              captchaToken: res,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
          if (!data.errorStatus) {
            localStorage.setItem("userToken", data.data.token);
            toast({
              title: "Success.",
              description: "User berhasil login.",
              status: "success",
              position: "top",
              duration: 1500,
              isClosable: true,
            });
            setTimeout(() => {
              routeChange();
            }, 2000);
          }
        } catch (err) {
          console.error(err.response);
          if (err.response.data.errors[0]?.adminData?.isSuspended) {
            toast({
              title: "Warning.",
              description: "Akun anda terkunci, mohon coba beberapa saat lagi.",
              status: "warning",
              position: "top",
              duration: 1500,
              isClosable: true,
            });
          } else {
            toast({
              title: "Warning.",
              description: "Email atau password salah. Silahkan coba lagi.",
              status: "warning",
              position: "top",
              duration: 1500,
              isClosable: true,
            });
          }
        }
      } else {
        toast({
          title: "Warning.",
          description: validation.message,
          status: validation.status,
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <div>
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link" onClick={(e) => clickActive(e)}>
              <User />
              Login
            </Tab>
          </TabList>
          <TabPanel>
            <Form
              style={{ height: "238px" }}
              className="form-horizontal auth-form">
              <FormGroup>
                <Input
                  required=""
                  name="login[username]"
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  id="login-page__input-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required=""
                  name="login[password]"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                  id="login-page__input-password"
                />
              </FormGroup>
              <div className="form-button">
                <Button
                  data-sitekey={process.env.REACT_APP_QBEE_SITE_KEY}
                  data-action="submit"
                  data-callback="onSubmit"
                  color="primary"
                  onClick={() => handleLogin()}
                  id="login-page__button-submit">
                  Login
                </Button>
              </div>
            </Form>
          </TabPanel>
        </Tabs>
      </Fragment>
    </div>
  );
};

export default withRouter(LoginTabset);
