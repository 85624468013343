import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import "./styles.css";
import { useGetDetailDownline } from "../../../hooks/api/useGetDetailDownline";
import { useEditDownline } from "../../../hooks/api/useEditDownline";
import { isEmpty } from "lodash";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { provinceAction } from "../../../store/actions/provinceAction";
import { cityAction } from "../../../store/actions/cityAction";
import { districtAction } from "../../../store/actions/districtAction";
import { urbanAction } from "../../../store/actions/urbanAction";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";

function EditDownline(props) {
  const { code } = useParams();
  const [data, setData] = useState({});
  const {
    data: dataDetail,
    isSuccess,
    refetch,
  } = useGetDetailDownline({ code });
  const { mutate } = useEditDownline();
  const [isEditRegion, setIsEditRegion] = useState(false);
  const [dataRegion, setDataRegion] = useState({});
  const dispatch = useDispatch();
  const { provinces } = useSelector(({ provinces }) => provinces);
  const cities = useSelector((state) => state.cities.cities);
  const districts = useSelector((state) => state.districts.districts);
  const urbans = useSelector((state) => state.urbans.urbans);
  const [uplineCode, setUplineCode] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    dispatch(provinceAction());
  }, []);

  useEffect(() => {
    let tempRegion = {};
    if (!isEmpty(dataDetail)) {
      setData(dataDetail);
      tempRegion.province = {
        value: dataDetail?.owner?.warehouse?.location?.province,
        label: dataDetail?.owner?.warehouse?.location?.province,
      };
      tempRegion.city = {
        value: dataDetail?.owner?.warehouse?.location?.city,
        label: dataDetail?.owner?.warehouse?.location?.city,
      };

      tempRegion.district = {
        value: dataDetail?.owner?.warehouse?.location?.district,
        label: dataDetail?.owner?.warehouse?.location?.district,
      };

      tempRegion.urban = {
        value: dataDetail?.owner?.warehouse?.location?.id,
        label: dataDetail?.owner?.warehouse?.location?.urban,
      };

      setUplineCode(dataDetail?.resellerStore?.uplineCode || null);
      setStoreCode(dataDetail?.owner?.uplineCode || null);
      setDataRegion(tempRegion);
    }
  }, [dataDetail]);

  const handleSubmit = () => {
    let patchData = { addressId: dataRegion?.urban?.value };

    if (uplineCode) {
      patchData.uplineCode = uplineCode;
    }

    if (storeCode) {
      patchData.storeCode = storeCode;
    }
    mutate(
      {
        code,
        patchData,
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            title: "Success.",
            description: "Berhasil mengubah status.",
            status: "success",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
          setTimeout(() => {
            history.push("/downline-management/list-downline");
          }, 1500);
        },
        onError: (err) => {
          console.error(err);

          toast({
            title: "Warning.",
            description:
              err.response.data.errors.join("") ||
              "Terjadi kesalahan, mohon coba beberapa saat lagi",
            status: "warning",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleClearInput = (keys, obj) => {
    keys.forEach((key) => {
      obj[key] = "";
    });
    return obj;
  };

  useEffect(() => {
    if (dataRegion?.province?.value) {
      dispatch(cityAction(dataRegion?.province?.value));

      dispatch(
        districtAction(dataRegion?.province?.value, dataRegion?.city?.value)
      );

      dispatch(
        urbanAction(
          dataRegion?.province?.value,
          dataRegion?.city?.value,
          dataRegion?.district?.value
        )
      );
    }
  }, [dataRegion]);

  const handleEditRegion = ({ value, type }) => {
    let tempRegion = { ...dataRegion };
    let keysToRemove = [];
    if (type === "province") {
      keysToRemove = ["city", "district", "urban"];
      handleClearInput(keysToRemove, tempRegion);
    } else if (type === "city") {
      keysToRemove = ["district", "urban"];
      handleClearInput(keysToRemove, tempRegion);
    } else if (type === "district") {
      keysToRemove = ["urban"];
      handleClearInput(keysToRemove, tempRegion);
    }
    tempRegion[type] = value;
    setDataRegion(tempRegion);
  };

  return (
    <Card>
      {isSuccess && (
        <>
          <CardHeader>
            <Text className="edit-downline__title">
              {data?.firstName || "First Name"} {data?.lastName || "Last Name"}
            </Text>

            <Flex alignItems="center">
              <Text className="edit-downline__subtitle">Store Info</Text>
              <Text className="edit-downline__subtitle" margin="0 8px">
                |
              </Text>
              <Text className="edit-downline__subtitle">Store Performance</Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Row>
              <Col md="6">
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Reseller Id *:</Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={code}
                      disabled
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Fullname *:</Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={`${data?.firstName} ${data?.lastName}`}
                      disabled
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Email *:</Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={data?.email}
                      disabled
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Current Level:</Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={data?.resellerStatus}
                      disabled
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Upline Code:</Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={uplineCode}
                      onChange={(e) =>
                        setUplineCode(e.target.value.toUpperCase())
                      }
                      type="text"
                      disabled={data?.resellerStatus?.includes("beyond")}
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Region:</Text>
                  </Col>
                  <Col md="6">
                    <InputGroup>
                      <Input
                        disabled={isEmpty(dataDetail.owner) || isEditRegion}
                        value={dataDetail?.owner?.warehouse?.location?.province}
                        _disabled={{ bg: "#AEAEAE" }}
                      />
                      {!isEmpty(dataDetail.owner) && (
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => setIsEditRegion(!isEditRegion)}
                          >
                            Edit
                          </Button>
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </Col>
                </Row>

                {isEditRegion && (
                  <Row className="edit-downline__wrapper-input">
                    <Col md="6" className="edit-downline__input-region">
                      <Text className="edit-downline__label" mb="0.5em">
                        Province:
                      </Text>
                      <Select
                        options={provinces?.map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })}
                        value={dataRegion.province}
                        onChange={(value) =>
                          handleEditRegion({ value, type: "province" })
                        }
                      />
                    </Col>

                    <Col md="6" className="edit-downline__input-region">
                      <Text className="edit-downline__label" mb="0.5em">
                        City:
                      </Text>
                      <Select
                        options={cities?.map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })}
                        value={dataRegion.city}
                        onChange={(value) =>
                          handleEditRegion({ value, type: "city" })
                        }
                      />
                    </Col>

                    <Col md="6" className="edit-downline__input-region">
                      <Text className="edit-downline__label" mb="0.5em">
                        District:
                      </Text>
                      <Select
                        options={districts?.map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })}
                        value={dataRegion.district}
                        onChange={(value) =>
                          handleEditRegion({ value, type: "district" })
                        }
                      />
                    </Col>

                    <Col md="6" className="edit-downline__input-region">
                      <Text className="edit-downline__label" mb="0.5em">
                        Urban:
                      </Text>
                      <Select
                        options={urbans?.map((item) => {
                          return {
                            value: item.id,
                            label: item.urban,
                          };
                        })}
                        value={dataRegion.urban}
                        onChange={(value) =>
                          handleEditRegion({ value, type: "urban" })
                        }
                      />
                    </Col>
                  </Row>
                )}

                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">Kode Toko:</Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={storeCode}
                      onChange={(e) =>
                        setStoreCode(e.target.value.toUpperCase())
                      }
                      disabled={isEmpty(dataDetail.owner)}
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">
                      Jumlah Downline:
                    </Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={data?.downlinesCount}
                      disabled
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
                <Row className="edit-downline__wrapper-input">
                  <Col md="3">
                    <Text className="edit-downline__label">
                      Transaksi Terakhir:{" "}
                    </Text>
                  </Col>
                  <Col md="6">
                    <Input
                      value={
                        !isEmpty(data?.lastOrderDate?.paidDate)
                          ? dateFormat(
                              data?.lastOrderDate.paidDate,
                              "dd/mm/yyyy HH:MM:ss"
                            )
                          : ""
                      }
                      disabled
                      _disabled={{ bg: "#AEAEAE" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Flex justifyContent="center" mt="2em">
              <Button
                color="#fff"
                background="#ff8084"
                mr="8px"
                width="200px"
                onClick={() =>
                  history.push("/downline-management/list-downline")
                }
              >
                Cancel
              </Button>
              <Button
                colorScheme="teal"
                size="md"
                width="200px"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Flex>
          </CardBody>
        </>
      )}
    </Card>
  );
}

export default EditDownline;
