import {
  SET_VIRTUAL_STORE_DATA,
  SET_VIRTUAL_STOCK_DATA,
  FETCH_ALL_VIRTUAL_STORE_LIST,
  LOGIN_VIRTUAL_STORE,
  SET_IS_CHOOSING_STORE,
} from "../actionTypes";

const initialState = {
  virtualStoreData: [],
  virtualStockData: [],
  isChoosing: false,
  virtualStores: [],
  loginData: {},
};

function virtualStoreReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VIRTUAL_STORE_DATA:
      return { ...state, virtualStoreData: action.payload };
    case SET_VIRTUAL_STOCK_DATA:
      return { ...state, virtualStockData: action.payload };
    case SET_IS_CHOOSING_STORE:
      return { ...state, isChoosing: action.payload };
    case FETCH_ALL_VIRTUAL_STORE_LIST:
      return { ...state, virtualStores: action.payload };
    case LOGIN_VIRTUAL_STORE:
      return { ...state, loginData: action.payload };
    default:
      return state;
  }
  er;
}

export default virtualStoreReducer;
