import { SET_DISTRICT } from "../actionTypes";
import Axios from "../../services/axios";

export function districtAction(province, city) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/user-address/districts?provinceName=${province}&cityName=${city}`,
        method: "GET",
      });
      dispatch({
        type: SET_DISTRICT,
        payload: data.data.districts,
      });
    } catch (error) {
      console.error(error);
    }
  };
}
