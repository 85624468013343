import React, { Fragment, useEffect } from "react";
import LoginTabset from "./loginTabset";
import { ArrowLeft } from "react-feather";
import Slider from "react-slick";
import stats from "../../assets/images/dashboard/stats.png";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

const Login = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
  };

  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      localStorage.removeItem("virtualUserToken");
      localStorage.removeItem("virtualStoreId");
      history.push("/dashboard");
    } else if (localStorage.getItem("virtualUserToken")) {
      localStorage.removeItem("userToken");
      history.push("/virtual-store/list-virtual")
    }
  }, []);
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="authentication-box">
          <Container>
            <Row>
              <Col className="col-md-5 p-0 card-left">
                <Card style={{ height: "400px" }} className="bg-primary">
                  <div className="svg-icon">
                    <img alt="" src={stats} className="Img-fluid" />
                  </div>
                  <div>
                    <div>
                      <h3>Welcome to Beyondly CMS </h3>
                      <p></p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col className="col-md-7 p-0 card-right">
                <Card className="tab2-card">
                  <CardBody>
                    <LoginTabset />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
