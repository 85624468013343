import ReactPaginate from "react-paginate";
import { Box } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

const Pagination = ({ totalPage, page, changePageHandler }) => {
  return (
    <Box className="pagination-container">
      <ReactPaginate
        previousLabel={<ChevronLeftIcon fontSize="24px" />}
        nextLabel={<ChevronRightIcon fontSize="24px" />}
        pageCount={totalPage}
        onPageChange={changePageHandler}
        breakLabel="..."
        breakClassName="pagination__item pagination__item--break"
        pageClassName="pagination__item"
        pageLinkClassName="pagination__item-link"
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        forcePage={page}
      />
    </Box>
  );
};

<svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M31.9997 5.3335C17.2957 5.3335 5.33301 17.2962 5.33301 32.0002C5.33301 46.7042 17.2957 58.6668 31.9997 58.6668C46.7037 58.6668 58.6663 46.7042 58.6663 32.0002C58.6663 17.2962 46.7037 5.3335 31.9997 5.3335Z"
    fill="#D9F2E3"
  />
  <path
    d="M18.667 20H45.3337V25.3333H18.667V20ZM41.3337 26.6667H20.0003V41.3333C20.0003 42.0406 20.2813 42.7189 20.7814 43.219C21.2815 43.719 21.9597 44 22.667 44H41.3337C42.0409 44 42.7192 43.719 43.2193 43.219C43.7194 42.7189 44.0003 42.0406 44.0003 41.3333V26.6667H41.3337ZM37.3337 34.6667H26.667V32H37.3337V34.6667Z"
    fill="#015352"
  />
</svg>;

export default Pagination;
