import { RESET_SIDEBAR } from "../actionTypes";

const initialState = {
  resetSidebar: false,
};

function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_SIDEBAR:
      return { ...state, resetSidebar: action.payload };
    default:
      return state;
  }
}

export default sidebarReducer;
