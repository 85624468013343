import {
  SET_ALL_ORDER_LIST,
  SET_ORDER_LIST,
  SET_ORDER_DETAIL,
  SET_REQUEST_DETAIL,
} from "../actionTypes";

const initialState = {
  orders: [],
  allorders: [],
  orderDetail: {},
  requestDetail: {},
};

function orderListReducer(state = initialState, action) {
  if (action.type === SET_ORDER_LIST) {
    return { ...state, orders: action.payload };
  } else if (action.type === SET_ALL_ORDER_LIST) {
    return { ...state, allorders: action.payload };
  } else if (action.type === SET_ORDER_DETAIL) {
    return { ...state, orderDetail: action.payload };
  } else if (action.type === SET_REQUEST_DETAIL) {
    return { ...state, requestDetail: action.payload };
  }
  return state;
}

export default orderListReducer;
