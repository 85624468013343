import { SET_URBAN } from "../actionTypes";
import Axios from "../../services/axios";

export function urbanAction(province, city, district) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/user-address/urbans?provinceName=${province}&cityName=${city}&districtName=${district}`,
        method: "GET",
      });
      dispatch({
        type: SET_URBAN,
        payload: data.data.urbans,
      });
    } catch (error) {
      console.error(error);
    }
  };
}
