import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { FileIcon, WarningIcon } from "./icons";
import "./style.css";
import * as XLSX from "xlsx";
import { useMassUpdateDownline } from "../../../hooks/api/useGetDownline";

const MassUploadModal = ({ isOpen, onClose }) => {
  const [uploadState, setUploadState] = useState("upload");
  const [file, setFile] = useState({});
  const [isWrongFile, setIsWrongFile] = useState(false);
  const [invalidRows, setInvalidRows] = useState([]);
  const [importedData, setImportedData] = useState([]);
  const { mutate: mutateMassUpdateDownline, isPending } =
    useMassUpdateDownline();
  const toast = useToast();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          `.xlsx`,
        ],
      },
      maxFiles: 1,
      maxSize: 10000000,
    });

  const handleFileRejection = () => {
    const findError = (errorCode) =>
      fileRejections.find((file) => file.errors[0].code === errorCode);

    const renderMessage = (errorMessage) => <>{errorMessage}</>;

    const tooManyFiles = findError("too-many-files");
    if (tooManyFiles)
      return renderMessage("Mohon pilih hanya 1 file .xlsx saja");

    const fileTooLarge = findError("file-too-large");
    if (fileTooLarge) return renderMessage("Ukuran dokumen melebihi 10Mb");

    const invalidFileType = findError("file-invalid-type");
    if (invalidFileType)
      return (
        <>
          Format file bukan .xlsx <br /> Silahkan unggah dengan format yang
          sesuai
        </>
      );
  };

  const parseData = (data) => {
    if (
      data.length > 0 &&
      data[0]["Kode Referral"] !== undefined &&
      data[0]["Kode Toko"] !== undefined &&
      data[0]["Upline Code"] !== undefined
    ) {
      setIsWrongFile(false);
      const parsedData = [];
      data.forEach((product) => {
        parsedData.push({
          referralCode: product["Kode Referral"],
          uplineCode: product["Upline Code"],
          storeCode: product["Kode Toko"],
        });
      });

      setImportedData(parsedData);
    } else {
      setIsWrongFile(true);
    }
  };

  const importFile = async () => {
    mutateMassUpdateDownline(
      {
        dataDownline: importedData,
      },
      {
        onSuccess: (res) => {
          if (res.data.failed) {
            setUploadState("failed");
            setInvalidRows(res.data.failedData);
            setImportedData([]);
            setFile({});
          } else {
            toast({
              title: "Berhasil.",
              description: "Berhasil import dokumen.",
              status: "success",
              position: "top",
              duration: 1500,
              isClosable: true,
            });
            onClose();
          }
        },
        onError: (err) => {
          console.error(err);
          toast({
            title: "Error.",
            description: "Gagal import dokumen. Silahkan coba lagi.",
            status: "error",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const reuploadFile = () => {
    setUploadState("upload");
    setImportedData([]);
    setInvalidRows([]);
    setFile({});
  };

  useEffect(() => {
    if (!isEmpty(acceptedFiles)) {
      setFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (!isEmpty(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        parseData(json);
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <Flex className="container">
          {uploadState === "upload" ? (
            <>
              <Text className="main-title">Import downline</Text>
              <Text className="sub-title">
                Upload dokumen yang ingin Anda masukkan di sini
              </Text>
              <Box>
                <Box {...getRootProps({ className: "stock-dropzone" })}>
                  <input id="input-file" {...getInputProps()} />
                  {!isEmpty(file) ? (
                    <>
                      <Text className="dropzone-filename">[{file.name}]</Text>
                      <Button className="button-flat">Ganti file</Button>
                    </>
                  ) : (
                    <>
                      <FileIcon />
                      <Text className="dropzone-info">
                        Format dokumen .xlsx <br />
                        Maksimal 10Mb.
                      </Text>
                      <Button className="button-outline" marginTop="16px">
                        Pilih file
                      </Button>
                    </>
                  )}
                </Box>
                {!isEmpty(fileRejections) && (
                  <Text className="error-text">{handleFileRejection()}</Text>
                )}
                {isWrongFile && (
                  <Text className="error-text">
                    Mohon pilih dokumen sesuai template
                  </Text>
                )}
              </Box>
              <Flex justifyContent="space-around">
                <Button className="button-flat" onClick={onClose}>
                  Batalkan
                </Button>
                <Button
                  className="button-fill"
                  isDisabled={
                    isEmpty(file) || !isEmpty(fileRejections) || isWrongFile
                  }
                  onClick={importFile}
                  isLoading={isPending}
                >
                  Import dokumen
                </Button>
              </Flex>
            </>
          ) : (
            uploadState === "failed" && (
              <>
                <Text className="main-title">Berhasil import dokumen</Text>
                <Flex className="warning-box">
                  <Box>
                    <WarningIcon />
                  </Box>
                  <Text className="warning-text">
                    Terdapat beberapa upline code yang bermasalah
                  </Text>
                </Flex>
                <Box className="warning-container">
                  <Text className="warning-title">
                    Upline code yang tidak dapat diupdate:
                  </Text>
                  <Box className="invalid-list-container">
                    {invalidRows.map((item, index) => (
                      <Flex>
                        <Text className="invalid-text-line">
                          Baris {index + 1}
                        </Text>
                        <Text className="invalid-text-product">
                          {item.referralCode}
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                </Box>
                <Box className="notes-container">
                  <Text className="notes-title">Catatan:</Text>
                  <Text className="notes-text">
                    Baris yang tidak error berhasil diupdate.
                  </Text>
                </Box>
                <Flex justifyContent="space-around">
                  <Button
                    className="button-outline"
                    width="fit-content"
                    onClick={onClose}
                  >
                    Tutup
                  </Button>
                  <Button className="button-fill" onClick={reuploadFile}>
                    Import ulang dokumen
                  </Button>
                </Flex>
              </>
            )
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default MassUploadModal;
