import { SET_PROMO, SET_SINGLE_PROMO } from "../actionTypes";

const initialState = {
  promos: {},
  singlePromo: {},
};

function promoReducer(state = initialState, action) {
  if (action.type === SET_PROMO) {
    return { ...state, promos: action.payload };
  } else if (action.type === SET_SINGLE_PROMO) {
    return { ...state, singlePromo: action.payload };
  }
  return state;
}

export default promoReducer;
