import { SET_RESELLER_LEVEL_LIST } from "../actionTypes"

const initialState = {
  resellerLevelList: [],
}

const resellerLevelListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESELLER_LEVEL_LIST:
      return { ...state, resellerLevelList: action.payload };
    default:
      return state;
  }
}

export default resellerLevelListReducer;