import Axios from "./axios";

export const postError = async (error) => {
  const userToken = localStorage.getItem("userToken");
  const config = {
    method: "POST",
    url: `/error-report${!userToken ? "/public" : "/admin"}`,
    data: {
      clientUrl: window.location.href,
      errorMessage: userToken
        ? `${localStorage.getItem("userEmail")} : ${error.message}`
        : error.message,
    },
  };

  if (userToken) {
    config.headers = {
      Authorization: `Bearer ${userToken}`,
    };
  }

  await Axios(config);
};

const loggingError = (error, info) => {
  // Do something with the error, e.g. log to an external API
  postError(error);
};

export default loggingError;
