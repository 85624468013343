import { useMutation } from "@tanstack/react-query";
import Axios from "../../services/axios";

export const useEditDownline = () => {
  return useMutation({
    mutationFn: async ({ code, patchData }) => {
      try {
        const response = await Axios({
          url: `/admin/downline/${code}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          data: patchData,
        });

        return response;
      } catch (err) {
        throw err;
      }
    },
  });
};
