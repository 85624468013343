import { SET_ADMIN_ROLE } from "../actionTypes";

const initialState = {
  adminRole: [],
};

function roleManageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_ROLE:
      return { ...state, adminRole: action.payload };
    default:
      return state;
  }
}

export default roleManageReducer;
