import { useMutation, useQuery } from "@tanstack/react-query";
import Axios from "../../services/axios";

export const useGetDownline = ({ queries }) => {
  return useQuery({
    queryKey: ["listDownline", queries],
    queryFn: async () => {
      const { data } = await Axios({
        url: `/admin/downline`,
        method: "GET",
        params: {
          ...queries,
          sort: "createdAt,desc",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      return data;
    },
  });
};

export const useGetDownlineExport = ({ province }) => {
  return useQuery({
    queryKey: ["listDownlineExport", province || "default"],
    queryFn: async () => {
      let params = { sort: "createdAt,desc" };
      if (province) {
        params.province = province;
      }
      const { data } = await Axios({
        url: `/admin/downline`,
        method: "GET",
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      return data.data.users || [];
    },
  });
};

export const useMassUpdateDownline = () => {
  return useMutation({
    mutationFn: async ({ dataDownline }) => {
      try {
        const { data } = await Axios({
          url: "/admin/downline/bulk",
          method: "PATCH",
          data: {
            data: dataDownline,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        return data;
      } catch (e) {
        throw e;
      }
    },
  });
};
