import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginVirtualStoreAction,
} from "../../store/actions/virtualStoreAction";
import { isEmpty } from "lodash";

const VirtualStoreLoginTabset = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };
  const toast = useToast();

  const { loginData } = useSelector(({ virtualStore }) => virtualStore);

  useEffect(() => {
    if (isEmpty(loginData)) return;
    if (loginData.errorStatus) {
      toast({
        title: "Error.",
        description: loginData.errors[0].message,
        status: "error",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      return;
    }

    localStorage.setItem("virtualUserToken", loginData.data.user.token);
    window.location.href = "/virtual-store/list-virtual";
  }, [loginData]);

  const handleValidInput = () => {
    let statusValidation = {
      status: "success",
      message: "Berhasil melakukan login.",
    };

    if (!email) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi email dahulu.";
    } else if (!password) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi password dahulu.";
    }

    return statusValidation;
  };

  const handleLogin = async () => {
    let validation = handleValidInput();
    if (validation.status !== "success") {
      toast({
        title: "Error.",
        description: validation.message,
        status: "error",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      return;
    }

    const payload = {
      username: email,
      password: password,
    };

    loginVirtualStoreAction(dispatch, payload);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <div>
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link" onClick={(e) => clickActive(e)}>
              <User />
              Login
            </Tab>
          </TabList>
          <TabPanel>
            <Form
              style={{ height: "238px" }}
              className="form-horizontal auth-form"
            >
              <FormGroup>
                <Input
                  required=""
                  name="login[username]"
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  id="login-page__input-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required=""
                  name="login[password]"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                  id="login-page__input-password"
                />
              </FormGroup>
              <div className="form-button">
                <Button
                  data-sitekey={process.env.REACT_APP_QBEE_SITE_KEY}
                  data-action="submit"
                  data-callback="onSubmit"
                  color="primary"
                  onClick={handleLogin}
                  id="login-page__button-submit"
                >
                  Login
                </Button>
              </div>
            </Form>
          </TabPanel>
        </Tabs>
      </Fragment>
    </div>
  );
};

export default withRouter(VirtualStoreLoginTabset);
