import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const LoginProvider = (props) => {
  const router = useHistory();
  const location = useLocation();
  const [userToken] = useState(localStorage.getItem("userToken"));
  const [virtualUserToken] = useState(localStorage.getItem("virtualUserToken"));

  useEffect(() => {
    if (location.pathname.includes("/virtual-store")) return;
    if (!userToken) {
      router.replace("/auth/login");
    }
  }, [userToken, virtualUserToken]);

  return <>{props.children}</>;
};

export default LoginProvider;
