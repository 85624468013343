import { GET_SERVICES } from "../actionTypes";

const initialState = {
  services: [],
};

function serviceReducer(state = initialState, action) {
  if (action.type === GET_SERVICES) {
    return { ...state, services: action.payload };
  }
  return state;
}

export default serviceReducer;
