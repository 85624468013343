import {
  CLEAN_USER_DETAIL_DATA,
  CLEAR_USER_STATE,
  SET_BEYOND_STAR_USER__STATS,
  SET_CREATE_USER,
  SET_PATCH_USER,
  SET_USER_ALL,
  SET_USER_BADGE_PROGRAM,
  SET_USER_BEYOND_STAR,
  SET_USER_BEYOND_STAR_ALL,
  SET_USER_COMMUNITY,
  SET_USER_DETAIL_DATA,
  SET_USER_LIST,
  SET_USER_STATS,
} from "../actionTypes";

const initialState = {
  users: [],
  userDetail: {},
  userAll: [],
  community: [],
  createResponse: {},
  userStats: {},
  badgeProgram: [],
  userBeyondStar: [],
  userBeyondStarAll: [],
  beyondStarUserStats: {},
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_LIST:
      return { ...state, users: action.payload };
    case SET_USER_DETAIL_DATA:
      return { ...state, userDetail: action.payload };
    case CLEAN_USER_DETAIL_DATA:
      return { ...state, userDetail: action.payload };
    case SET_USER_ALL:
      return { ...state, userAll: action.payload };
    case SET_USER_COMMUNITY:
      return { ...state, community: action.payload };
    case SET_CREATE_USER:
      return { ...state, createResponse: action.payload };
    case SET_PATCH_USER:
      return { ...state, createResponse: action.payload };
    case CLEAR_USER_STATE:
      return { ...state, createResponse: action.payload };
    case SET_USER_STATS:
      return { ...state, userStats: action.payload };
    case SET_USER_BADGE_PROGRAM:
      return { ...state, badgeProgram: action.payload };
    case SET_USER_BEYOND_STAR:
      return { ...state, userBeyondStar: action.payload };
    case SET_USER_BEYOND_STAR_ALL:
      return { ...state, userBeyondStarAll: action.payload };
    case SET_BEYOND_STAR_USER__STATS:
      return { ...state, beyondStarUserStats: action.payload };
    default:
      return state;
  }
}

export default userReducer;
