import { SET_FAQ_LIST } from "../actionTypes";

const initialState = {
  faqs: [],
};

function faqsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FAQ_LIST:
      return { ...state, faqs: action.payload };
    default:
      return state;
  }
}

export default faqsReducer;
