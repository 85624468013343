import {
  SET_GROWTH_POINT,
  SET_GROWTH_POINT_LOG,
  SET_GP_DETAIL_USER,
  SET_GP_USER_TOTAL_GP,
  SET_GP_USER_BY_REFERRAL,
  SET_EMPTY_GP_USER,
} from "../actionTypes";

const initialState = {
  growthPoint: {},
  growthPointLog: {},
  gpDetail: [],
  gpUser: {},
  gpTotal: {},
};

function growthPointReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GROWTH_POINT:
      return { ...state, growthPoint: action.payload };
    case SET_GP_DETAIL_USER:
      return { ...state, gpUser: action.payload };
    case SET_GP_USER_TOTAL_GP:
      return { ...state, gpTotal: action.payload };
    case SET_GP_USER_BY_REFERRAL:
      return { ...state, gpUser: action.payload };
    case SET_EMPTY_GP_USER:
      return { ...state, gpUser: {} };
    case SET_GROWTH_POINT_LOG:
      return { ...state, growthPointLog: action.payload };
    default:
      return state;
  }
}

export default growthPointReducer;
