import { EDIT_REDEMPTION_REWARD } from "../actionTypes";

const initialState = {
  redemptionReward: "",
};

function redemptionRewardReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_REDEMPTION_REWARD:
      return { ...state, redemptionReward: action.payload };
    default:
      return state;
  }
}

export default redemptionRewardReducer;
