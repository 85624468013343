import { useQuery } from "@tanstack/react-query";
import Axios from "../../services/axios";

export const useGetDetailDownline = ({ code }) => {
  return useQuery({
    queryKey: ["detailDownline", code],
    queryFn: async () => {
      const { data } = await Axios({
        url: `/admin/downline/${code}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      return data?.data?.user || {};
    },
  });
};
