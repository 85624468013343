import { SET_PROVINCE } from "../actionTypes";

const initialState = {
  provinces: [],
};

function provinceReducer(state = initialState, action) {
  if (action.type === SET_PROVINCE) {
    return { ...state, provinces: action.payload };
  }
  return state;
}

export default provinceReducer;
