import { configureStore, combineReducers } from "@reduxjs/toolkit";
import stockReducer from "./reducers/stockReducer";
import provinceReducer from "./reducers/provinceReducer";
import cityReducer from "./reducers/cityReducer";
import warehouseReducer from "./reducers/warehouseReducer";
import orderListReducer from "./reducers/order-list";
import promoReducer from "./reducers/promoReducer";
import growthPointReducer from "./reducers/growthPointReducer";
import productShowcaseReducer from "./reducers/productShowcaseReducer";
import productReviewReducer from "./reducers/productReviewReducer";
import commissionReducers from "./reducers/commissionReducers";
import districtReducer from "./reducers/districtReducer";
import urbanReducer from "./reducers/urbanReducer";
import userReducer from "./reducers/userReducer";
import faqsReducer from "./reducers/faqsReducer";
import sidebarReducer from "./reducers/sideBarReducer";
import serviceReducer from "./reducers/serviceReducer";
import redemptionRewardReducer from "./reducers/redemptionRewardReducer";
import roleManageReducer from "./reducers/roleManageReducer";
import resellerLevelListReducer from "./reducers/resellerLevelListReducer";
import crudVirtualStoreReducer from "./reducers/crudVirtualStoreReducers";
import virtualStoreReducer from "./reducers/virtualStoreReducer";
import virtualStoreOrderReducer from "./reducers/virtualStoreOrderReducers";

const reducers = combineReducers({
  stock: stockReducer,
  provinces: provinceReducer,
  cities: cityReducer,
  districts: districtReducer,
  urbans: urbanReducer,
  warehouse: warehouseReducer,
  orders: orderListReducer,
  promos: promoReducer,
  growthPoint: growthPointReducer,
  productShowcase: productShowcaseReducer,
  productReview: productReviewReducer,
  commission: commissionReducers,
  users: userReducer,
  faqs: faqsReducer,
  sidebar: sidebarReducer,
  services: serviceReducer,
  redemptionReward: redemptionRewardReducer,
  roleManagement: roleManageReducer,
  resellerLevelList: resellerLevelListReducer,
  crudVirtualStore: crudVirtualStoreReducer,
  virtualStore: virtualStoreReducer,
  virtualOrders: virtualStoreOrderReducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
    }),
});

export default store;
