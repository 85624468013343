import React, { Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";
import { Card, Col, Container, Row } from "reactstrap";
import navguard from "./auth/navguard";
import { Flex, Text, Box } from "@chakra-ui/react";
import LogoImage from "../assets/images/logo-beyondly.png";

const Dashboard = () => {
  return (
    <Fragment>
      <Breadcrumb title="Home" parent="Home" />
      <Container fluid={true}>
        <Row>
          <Col md="12">
            <Card style={{ height: "70vh" }}>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="100%">
                <Text fontSize="32px" marginBottom="16px">
                  Welcome To
                </Text>
                <Box height="200px" width="250px">
                  <img src={LogoImage} />
                </Box>
              </Flex>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

// javascript:void(0)

export default navguard(Dashboard);
