import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { MENUITEMS } from "../../../constants/menu";

// image import
import logo from "../../../assets/images/logo-beyondly.png";
import { useDispatch, useSelector } from "react-redux";
import { resetSidebarAction } from "../../../store/actions/sideBarAction";
import { roleManageAction } from "../../../store/actions/roleManageAction";

const Sidebar = () => {
  const userToken = localStorage.getItem("userToken");
  const virtualUserToken = localStorage.getItem("virtualUserToken");
  const dispatch = useDispatch();

  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [permittedId, setPermittedId] = useState([]);

  const { resetSidebar } = useSelector(({ sidebar }) => sidebar);
  const { adminRole } = useSelector(({ roleManagement }) => roleManagement);

  useEffect(() => {
    if (userToken) roleManageAction(dispatch);
  }, []);

  const filterAllowedPermissions = () => {
    const allAllowedPermissions = adminRole.admin.adminRole.permissions.map(
      (e) => e.feature
    );
    setPermittedId(allAllowedPermissions);
  };

  useEffect(() => {
    if (isEmpty(adminRole)) return;
    filterAllowedPermissions();
  }, [adminRole]);

  useEffect(() => {
    MENUITEMS.map((item) => {
      item.active = false;
    });

    resetSidebarAction(dispatch, false);
  }, [resetSidebar]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const tempMainMenu = [...mainmenu];
    for (let idxParent = 0; idxParent < tempMainMenu.length; idxParent += 1) {
      if (tempMainMenu[idxParent].path === currentUrl) {
        setNavActive(tempMainMenu[idxParent]);
        tempMainMenu[idxParent].active = true;
      } else if (!isEmpty(tempMainMenu[idxParent].children)) {
        let isChildrenFound = false;
        for (
          let idxChildren = 0;
          idxChildren < tempMainMenu[idxParent].children.length;
          idxChildren += 1
        ) {
          if (
            tempMainMenu[idxParent].children[idxChildren].path === currentUrl
          ) {
            setNavActive(tempMainMenu[idxParent].children[idxChildren]);
            tempMainMenu[idxParent].children[idxChildren].active = true;
            isChildrenFound = true;
          } else {
            tempMainMenu[idxParent].children[idxChildren].active = false;
          }
        }
        tempMainMenu[idxParent].active = isChildrenFound;
      } else {
        tempMainMenu[idxParent].active = false;
      }
    }

    setMainMenu(tempMainMenu);
  }, [window.location.pathname]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    mainmenu.map((items) => {
      renderMainMenu.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    return () => {
      setMainMenu(MENUITEMS);
    };
  });

  const setNavActive = (item) => {
    MENUITEMS.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems !== item) {
            submenuItems.active = false;
          }
          if (submenuItems.children) {
            submenuItems.children.map((childItem) => {
              return (childItem.active = false);
            });
            if (submenuItems.children.includes(item)) {
              submenuItems.active = true;
              menuItem.active = true;
            }
          }
          return false;
        });
      }
      return false;
    });
    item.active = !item.active;
    setMainMenu(MENUITEMS);
  };

  const renderMainMenu = mainmenu
    .filter((e) => {
      if (isEmpty(e.children)) return e;

      let tempArray = [];
      e.children.forEach((item) => {
        if (!isEmpty(item.permissionId)) {
          tempArray.push(...item.permissionId);
        }
      });

      if (tempArray.length) {
        const hasAllElems = tempArray.some((elem) =>
          permittedId.includes(elem)
        );

        return hasAllElems;
      } else {
        return e;
      }
    })
    .map((menuItem, i) => (
      <li className={`${menuItem.active ? "active" : ""}`} key={i}>
        {menuItem.sidebartitle ? (
          <div className="sidebar-title">{menuItem.sidebartitle}</div>
        ) : (
          ""
        )}
        {menuItem.type === "sub" ? (
          <a
            className="sidebar-header "
            href="#javaScript"
            onClick={() => setNavActive(menuItem)}
          >
            <menuItem.icon />
            <span>{menuItem.title}</span>
            <i className="fa fa-angle-right pull-right"></i>
          </a>
        ) : (
          ""
        )}
        {menuItem.type === "link" ? (
          <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
            className={`sidebar-header ${menuItem.active ? "active" : ""}`}
            onClick={() => setNavActive(menuItem)}
          >
            <menuItem.icon />
            <span>{menuItem.title}</span>
            {menuItem.children ? (
              <i className="fa fa-angle-right pull-right"></i>
            ) : (
              ""
            )}
          </Link>
        ) : (
          ""
        )}
        {menuItem.children ? (
          <ul
            className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
            style={
              menuItem.active
                ? { opacity: 1, transition: "opacity 500ms ease-in" }
                : {}
            }
          >
            {menuItem.children
              .filter((e) => {
                if (isEmpty(e.permissionId)) return e;
                const filteredChild = e.permissionId.some((item) =>
                  permittedId.includes(item)
                );
                return filteredChild;
              })
              .map((childrenItem, index) => (
                <li
                  key={index}
                  className={
                    childrenItem.children
                      ? childrenItem.active
                        ? "active"
                        : ""
                      : ""
                  }
                >
                  {childrenItem.type === "sub" ? (
                    <a
                      href="#javaScript"
                      onClick={() => setNavActive(childrenItem)}
                    >
                      <i className="fa fa-circle"></i>
                      {childrenItem.title}{" "}
                      <i className="fa fa-angle-right pull-right"></i>
                    </a>
                  ) : (
                    ""
                  )}

                  {childrenItem.type === "link" ? (
                    <>
                      {childrenItem.active ? (
                        <a
                          className={childrenItem.active ? "active" : ""}
                          onClick={() => setNavActive(childrenItem)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-circle"></i>
                          {childrenItem.title}{" "}
                        </a>
                      ) : (
                        <Link
                          to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                          className={childrenItem.active ? "active" : ""}
                          onClick={() => setNavActive(childrenItem)}
                        >
                          <i className="fa fa-circle"></i>
                          {childrenItem.title}{" "}
                        </Link>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {childrenItem.children ? (
                    <ul
                      className={`sidebar-submenu ${
                        childrenItem.active ? "menu-open" : "active"
                      }`}
                    >
                      {childrenItem.children.map((childrenSubItem, key) => (
                        <li
                          className={childrenSubItem.active ? "active" : ""}
                          key={key}
                        >
                          {childrenSubItem.type === "link" ? (
                            <Link
                              to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                              className={childrenSubItem.active ? "active" : ""}
                              onClick={() => setNavActive(childrenSubItem)}
                            >
                              <i className="fa fa-circle"></i>
                              {childrenSubItem.title}
                            </Link>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))}
          </ul>
        ) : (
          ""
        )}
      </li>
    ));

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img className="blur-up lazyloaded" src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          {((!isEmpty(permittedId) && !isEmpty(userToken)) ||
            !isEmpty(virtualUserToken)) && (
            <ul className="sidebar-menu">{renderMainMenu}</ul>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
