import React, { Suspense } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import Loader from "./loader/Loader";

const App = (props) => {
  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <RightSidebar />
          <Suspense fallback={<Loader />}>
            <div className="page-body">{props.children}</div>
          </Suspense>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default App;
