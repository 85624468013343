import { SET_ADMIN_ROLE } from "../actionTypes";
import Axios from "../../services/axios";

export const roleManageAction = async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/admin/get-data`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    if (data?.data?.admin?.isSuspended) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      dispatch({
        type: SET_ADMIN_ROLE,
        payload: data.data,
      });
    }
  } catch (error) {
    console.error("[ERROR]", error);
  }
};
