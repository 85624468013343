import {
  SET_WAREHOUSE,
  SET_WAREHOUSE_DETAIL,
  SET_WAREHOUSE_PRODUCT_INCLUDE,
  SET_WAREHOUSE_PRODUCT_EXCLUDE,
} from "../actionTypes";

const initialState = {
  warehouses: [],
  warehouse: {},
  warehouseProductsInclude: [],
  warehouseProductsExclude: [],
};

function warehouseReducer(state = initialState, action) {
  if (action.type === SET_WAREHOUSE) {
    return { ...state, warehouses: action.payload };
  } else if (action.type === SET_WAREHOUSE_DETAIL) {
    return { ...state, warehouse: action.payload };
  } else if (action.type === SET_WAREHOUSE_PRODUCT_INCLUDE) {
    return { ...state, warehouseProductsInclude: action.payload };
  } else if (action.type === SET_WAREHOUSE_PRODUCT_EXCLUDE) {
    return { ...state, warehouseProductsExclude: action.payload };
  }
  return state;
}

export default warehouseReducer;
