import {
  SET_COMMISSION,
  SET_TOTAL_COMMISSION,
  SET_USER_COMMISSION,
  SET_DETAIL_COMMISSION,
  SET_ALL_COMMISSION,
  SET_COMMISSION_MANAGEMENT
} from "../actionTypes";

const innitialState = {
  allCommission: [],
  commission: [],
  totalCommission: 0,
  userCommission: [],
  comissionDetail: [],
  commissionManagement: []
};

function commissionReducers(state = innitialState, action) {
  switch (action.type) {
    case SET_COMMISSION:
      return { ...state, commission: action.payload };
    case SET_ALL_COMMISSION:
      return { ...state, allCommission: action.payload };
    case SET_TOTAL_COMMISSION:
      return { ...state, totalCommission: action.payload };
    case SET_USER_COMMISSION:
      return { ...state, userCommission: action.payload };
    case SET_DETAIL_COMMISSION:
      return { ...state, comissionDetail: action.payload };
    case SET_COMMISSION_MANAGEMENT:
      return { ...state, commissionManagement: action.payload };
    default:
      return state;
  }
}

export default commissionReducers;
