import { RESET_SIDEBAR } from "../actionTypes";

export const resetSidebarAction = async (dispatch, stateValue) => {
  try {
    dispatch({
      type: RESET_SIDEBAR,
      payload: stateValue,
    });
  } catch (error) {
    console.error("[ERROR]", error);
  }
};
