import {
  FETCH_ALL_VIRTUAL_STORE_LIST,
  LOGIN_VIRTUAL_STORE,
  SET_IS_CHOOSING_STORE,
  SET_VIRTUAL_STORE_DATA,
  SET_VIRTUAL_STOCK_DATA,
} from "../actionTypes";
import Axios from "../../services/axios";

export const fetchStoreData = async (dispatch) => {
  const virtualUserToken = localStorage.getItem("virtualUserToken");
  const virtualStoreId = localStorage.getItem("virtualStoreId");

  try {
    const { data } = await Axios({
      url: `/virtual-store/${virtualStoreId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${virtualUserToken}`,
      },
    });

    dispatch({
      type: SET_VIRTUAL_STORE_DATA,
      payload: data.data.warehouse,
    });
  } catch (err) {
    console.error(err.response);
  }
};

export const fetchStoreStock = async (dispatch, params) => {
  const virtualUserToken = localStorage.getItem("virtualUserToken");
  const virtualStoreId = localStorage.getItem("virtualStoreId");

  try {
    const { data } = await Axios({
      url: `/virtual-store/${virtualStoreId}/stock/list`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${virtualUserToken}`,
      },
      params,
    });

    dispatch({
      type: SET_VIRTUAL_STOCK_DATA,
      payload: data.data.stocks.sort((a, b) =>
        a.product.name > b.product.name ? 1 : -1
      ),
    });
  } catch (err) {
    console.error(err.response);
  }
};

export const updateStoreStock = (value) => {
  return async (dispatch) => {
    const virtualUserToken = localStorage.getItem("virtualUserToken");
    const virtualStoreId = localStorage.getItem("virtualStoreId");

    try {
      await Axios({
        url: `/virtual-store/${virtualStoreId}/stock/${value.productId}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${virtualUserToken}`,
        },
        data: {
          minSafetyStock: value.minSafetyStock,
          availableStock: value.availableStock,
        },
      });
      fetchStoreStock(dispatch);
    } catch (err) {
      console.error(err.response);
    }
  };
};

export const setIsChoosingStore = async (dispatch, isChoosing) => {
  dispatch({
    type: SET_IS_CHOOSING_STORE,
    payload: isChoosing,
  });
};

export const loginVirtualStoreAction = async (dispatch, value) => {
  try {
    const { data } = await Axios({
      method: "POST",
      url: `/virtual-store/login`,
      data: value,
    });

    dispatch({
      type: LOGIN_VIRTUAL_STORE,
      payload: data,
    });
    return data;
  } catch (error) {
    console.error("error login virtual store: ", error);
    dispatch({
      type: LOGIN_VIRTUAL_STORE,
      payload: error.response.data,
    });
  }
};

export const fetchAllVirtualStoreList = async (dispatch) => {
  try {
    const { data } = await Axios({
      method: "GET",
      url: `/virtual-store/list`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("virtualUserToken")}`,
      },
    });

    dispatch({
      type: FETCH_ALL_VIRTUAL_STORE_LIST,
      payload: data.data,
    });
  } catch (error) {
    console.error("error fetch virtual store list: ", error);
  }
};
