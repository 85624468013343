import { SET_DISTRICT } from "../actionTypes";

const initialState = {
  districts: [],
};

function districtReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DISTRICT:
      return { ...state, districts: action.payload };
    default:
      return state;
  }
}

export default districtReducer;
