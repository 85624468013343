import { SET_CITY } from "../actionTypes";

const initialState = {
  cities: [],
};

function cityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CITY:
      return { ...state, cities: action.payload };
    default:
      return state;
  }
}

export default cityReducer;
