import {
  SET_PRODUCT_REVIEW_APPROVAL,
  SET_PRODUCT_REVIEW_DATA,
  SET_REVIEWED_PRODUCT_DATA,
} from "../actionTypes";

const initialState = {
  productReview: [],
  productReviewData: [],
  unreviewedProduct: [],
};

const productReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_REVIEW_APPROVAL:
      // trigger there is some data changes on isReview
      return { ...state, productReview: action.payload };
    case SET_PRODUCT_REVIEW_DATA:
      return { ...state, productReviewData: action.payload };
    case SET_REVIEWED_PRODUCT_DATA:
      return { ...state, unreviewedProduct: action.payload };
    default:
      return state;
  }
};

export default productReviewReducer;
