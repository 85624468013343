import {
  Box,
  Flex,
  Input,
  Text,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import "./styles.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { provinceAction } from "../../../store/actions/provinceAction";
import { isEmpty } from "lodash";
import queryString from "query-string";
import Pagination from "../../order/components/pagination";
import {
  useGetDownline,
  useGetDownlineExport,
} from "../../../hooks/api/useGetDownline";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MassUploadModal from "../components/massUploadModal";
import { useActivateStore } from "../../../hooks/api/useActivateStore";

function ListDownline() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataTable, setDataTable] = useState([]);
  const [optionsProvince, setOptionsProvince] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const { search } = useLocation();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [queries, setQueries] = useState({});
  const { data: listDownline, refetch: refetchDownline } = useGetDownline({
    queries: queries,
  });
  const { mutate } = useActivateStore();

  const {
    data: listDownlineExport,
    isLoading: isLoadingExport,
    refetch: refetchExport,
  } = useGetDownlineExport({
    province: queries?.province || "",
  });

  const [query, setQuery] = useState("");
  const history = useHistory();

  const { provinces } = useSelector(({ provinces }) => provinces);
  const dispatch = useDispatch();

  const goToDetail = ({ referralCode }) => {
    history.push(`/downline-management/edit-downline/${referralCode}`);
  };

  const activateStoreHandler = async (referralCode) => {
    mutate(
      {
        referralCode,
      },
      {
        onSuccess: (res) => {
          toast({
            title: "Berhasil.",
            description: "Berhasil mengaktifkan store.",
            status: "success",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
          refetchDownline();
          refetchExport();
        },
        onError: (err) => {
          console.error(err);
          toast({
            title: "Error.",
            description: "Gagal mengaktifkan store.",
            status: "error",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const ExpandedComponent = ({ data, state }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onAccordionChange = (index) => {
      setActiveIndex(index);
    };

    return (
      <>
        {data?.downlines?.length ? (
          <Accordion allowToggle onChange={onAccordionChange}>
            <AccordionItem
              className={
                state === "parent"
                  ? activeIndex === 0
                    ? "list-downline-expanded-active"
                    : "list-downline-expanded-main"
                  : "list-downline-expanded-grandchild"
              }>
              <AccordionButton padding="0">
                <Flex className="list-downline__expanded-component-container">
                  <p className="list-downline__expanded-component-text">
                    {data?.firstName} {data?.lastName}
                  </p>
                  <p className="list-downline__expanded-component-text">
                    {data?.resellerStatus}
                  </p>
                  <p className="list-downline__expanded-component-text">
                    {data?.owner?.warehouse?.location?.province || "-"}
                  </p>
                  <p className="list-downline__expanded-component-text">
                    {data?.owner?.uplineCode || "-"}
                  </p>

                  <Box className="list-downline__expanded-component-text">
                    <Button
                      color="#fff"
                      background="#ff8084"
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        goToDetail({ referralCode: data?.referralCode })
                      }
                      borderRadius="999px">
                      Edit
                    </Button>

                    {!data?.owner && state === "parent" && (
                      <Button
                        colorScheme="teal"
                        variant="ghost"
                        size="sm"
                        onClick={() => activateStoreHandler(data?.referralCode)}
                        borderRadius="999px">
                        Activate
                      </Button>
                    )}
                  </Box>
                </Flex>
                <Flex
                  width="48px"
                  height="32px"
                  alignItems="center"
                  justifyContent="center">
                  <AccordionIcon width="24px" height="24px" />
                </Flex>
              </AccordionButton>
              <AccordionPanel
                background={state === "child" ? "white" : "#fff1f0"}
                padding="0">
                {data?.downlines?.map((downline, index) => (
                  <ExpandedComponent
                    data={downline}
                    key={index}
                    state="child"
                  />
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : (
          <Flex
            className="list-downline__expanded-component-container"
            borderBottom="1px solid #E2E8F0">
            <p className="list-downline__expanded-component-text">
              {data?.firstName} {data?.lastName}
            </p>
            <p className="list-downline__expanded-component-text">
              {data?.resellerStatus}
            </p>
            <p className="list-downline__expanded-component-text">
              {data?.owner?.warehouse?.location?.province || "-"}
            </p>
            <p className="list-downline__expanded-component-text">
              {data?.owner?.uplineCode || "-"}
            </p>
            <Box className="list-downline__expanded-component-text">
              <Button
                color="#fff"
                background="#ff8084"
                variant="ghost"
                size="sm"
                onClick={() => goToDetail({ referralCode: data?.referralCode })}
                borderRadius="999px"
                mr="4px">
                Edit
              </Button>
              {!data?.owner && state === "parent" && (
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  size="sm"
                  onClick={() => activateStoreHandler(data?.referralCode)}
                  borderRadius="999px">
                  Activate
                </Button>
              )}
            </Box>

            <Box flex="0 0 48px" />
          </Flex>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(provinceAction());
  }, []);

  useEffect(() => {
    if (!isEmpty(provinces)) {
      let tempProvinces = provinces.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      setOptionsProvince(tempProvinces);
    }
  }, [provinces]);

  const fetchData = async () => {
    const currentQuery = queryString.parse(search);
    const tempQueries = {
      ...currentQuery,
      page: parseInt(currentQuery.page || 1),
      limit: 10,
    };

    setQueries(tempQueries);
  };

  useEffect(() => {
    setDataTable(listDownline?.data?.users || []);
    setTotalPage(listDownline?.data?.totalPage || 0);
  }, [listDownline]);

  useEffect(() => {
    fetchData();
  }, [search]);

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.q) {
      setQuery(currentQuery.q);
    }

    if (currentQuery.province) {
      setSelectedRegion({
        value: currentQuery.province,
        label: currentQuery.province,
      });
    }

    if (currentQuery.page) {
      setPage(parseInt(currentQuery.page));
    }
  }, [search]);

  const handlePage = (value) => {
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        page: value.selected + 1,
      },
      { skipEmptyString: true }
    );

    history.push(`/downline-management/list-downline?${queries}`);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          page: 1,
          q: query,
        },
        { skipEmptyString: true }
      );

      history.push(`/downline-management/list-downline?${queries}`);
    }
  };

  const handleSelectRegion = (value) => {
    setSelectedRegion(value);
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        page: 1,
        province: value?.value || "",
      },
      { skipEmptyString: true }
    );

    history.push(`/downline-management/list-downline?${queries}`);
  };

  const populateData = () => {
    let selectedData = [
      ["Name", "Kode Referral", "Region", "Kode Toko", "Upline Code"],
    ];
    let temp = [];
    listDownlineExport?.forEach((item) => {
      temp.push([
        `${item.firstName} ${item.lastName}`,
        item?.referralCode || "-",
        item?.owner?.warehouse?.location?.province || "-",
        item?.owner?.uplineCode || "-",
        item?.resellerStore?.uplineCode || "-",
      ]);
    });

    return [...selectedData, ...temp];
  };

  const exportToXLS = () => {
    const populated = populateData();

    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: ".xlsx" });

    FileSaver.saveAs(data, `${userFileNameHandler()}.xlsx`);
  };

  const userFileNameHandler = () => {
    const date = new Date();
    const today = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return `DownlineUser - ${today}`;
  };

  return (
    <>
      <MassUploadModal isOpen={isOpen} onClose={onClose} />
      <Box className="list-downline__container">
        <Flex justifyContent="space-between" alignItems="center">
          <Text className="list-downline__title">Downline Management</Text>
          <Flex>
            <Button
              colorScheme="teal"
              size="md"
              mr="8px"
              disabled={isLoadingExport}
              onClick={exportToXLS}>
              Export
            </Button>

            <Button
              onClick={onOpen}
              color="#fff"
              background="#ff8084"
              variant="ghost">
              Mass upload
            </Button>
          </Flex>
        </Flex>

        <Box className="list-downline__content-container">
          <Input
            placeholder="Search user here"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
          />

          <Flex alignItems="center" m="12px 0">
            <Text className="list-downline__filter">Region: </Text>
            <ReactSelect
              placeholder="Choose region"
              options={optionsProvince}
              onChange={handleSelectRegion}
              isClearable
              value={selectedRegion}
              className="list-downline__filter-select"
            />
          </Flex>

          <Box>
            {!isEmpty(dataTable) && (
              <>
                <Flex flexDirection="column">
                  <Flex
                    className="list-downline__expanded-component-container"
                    borderBottom="1px solid #E2E8F0">
                    <p className="list-downline__expanded-component-text">
                      Nama User
                    </p>
                    <p className="list-downline__expanded-component-text">
                      Level
                    </p>
                    <p className="list-downline__expanded-component-text">
                      Region
                    </p>
                    <p className="list-downline__expanded-component-text">
                      Kode Toko
                    </p>

                    <p className="list-downline__expanded-component-text" />

                    <Box flex="0 0 48px" />
                  </Flex>
                  {dataTable.map((item, index) => (
                    <ExpandedComponent data={item} key={index} state="parent" />
                  ))}
                </Flex>
                <Box mt="1em">
                  <Pagination
                    page={page - 1}
                    changePageHandler={handlePage}
                    totalPage={totalPage}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ListDownline;
