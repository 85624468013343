import { useHistory } from "react-router-dom";

const navguard = (WrappedComponents) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const router = useHistory();

      const userToken = localStorage.getItem("userToken");
      const virtualUserToken = localStorage.getItem("virtualUserToken");

      if (virtualUserToken) {
        localStorage.removeItem("userToken");
        router.replace("/virtual-store/list-virtual");
      } else if (!userToken && router.pathname !== "/virtual-store") {
        router.replace("/auth/login");
      } else {
        return <WrappedComponents {...props} />;
      }
    }

    return null;
  };
};

export default navguard;
