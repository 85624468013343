export const FileIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.806 17.172C39.7112 16.9549 39.5784 16.7565 39.414 16.586L27.414 4.586C27.2435 4.42156 27.0451 4.28882 26.828 4.194C26.768 4.166 26.704 4.15 26.64 4.128C26.4727 4.07105 26.2984 4.03675 26.122 4.026C26.08 4.022 26.042 4 26 4H12C9.794 4 8 5.794 8 8V40C8 42.206 9.794 44 12 44H36C38.206 44 40 42.206 40 40V18C40 17.958 39.978 17.92 39.974 17.876C39.9642 17.6994 39.9299 17.5251 39.872 17.358C39.852 17.294 39.834 17.232 39.806 17.172ZM33.172 16H28V10.828L33.172 16ZM12 40V8H24V18C24 18.5304 24.2107 19.0391 24.5858 19.4142C24.9609 19.7893 25.4696 20 26 20H36L36.004 40H12Z"
      fill="#808080"
    />
    <path
      d="M16 24H32V28H16V24ZM16 32H32V36H16V32ZM16 16H20V20H16V16Z"
      fill="#808080"
    />
  </svg>
);

export const WarningIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9452 2.33331C7.54254 2.33331 2.33337 7.56698 2.33337 14C2.33337 20.433 7.56704 25.6666 14 25.6666C20.433 25.6666 25.6667 20.433 25.6667 14C25.6667 7.56698 20.4085 2.33331 13.9452 2.33331ZM14 23.3333C8.85387 23.3333 4.66671 19.1461 4.66671 14C4.66671 8.85381 8.82821 4.66665 13.9452 4.66665C19.1229 4.66665 23.3334 8.85381 23.3334 14C23.3334 19.1461 19.1462 23.3333 14 23.3333Z"
      fill="#997000"
    />
    <path
      d="M12.8334 8.16669H15.1667V16.3334H12.8334V8.16669ZM12.8334 17.5H15.1667V19.8334H12.8334V17.5Z"
      fill="#997000"
    />
  </svg>
);
