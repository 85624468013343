import {
  EMPTY_SHOWCASE_STATE,
  GET_PRODUCT_SHOWCASE,
  GET_PRODUCT_SHOWCASE_EDIT,
  SET_IMAGE_DESKTOP_PRODUCT_SHOWCASE,
  SET_IMAGE_MOBILE_PRODUCT_SHOWCASE,
} from "../actionTypes";

const initialState = {
  productShowcase: [],
  showcaseEdit: {},
  imageUploadDesk: [],
  imageUploadMob: [],
};

function productShowcaseReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_SHOWCASE:
      return { ...state, productShowcase: action.payload };
    case GET_PRODUCT_SHOWCASE_EDIT:
      return { ...state, showcaseEdit: action.payload };
    case SET_IMAGE_DESKTOP_PRODUCT_SHOWCASE:
      return {
        ...state,
        imageUploadDesk: [action.payload, ...state.imageUploadDesk],
      };
    case SET_IMAGE_MOBILE_PRODUCT_SHOWCASE:
      return {
        ...state,
        imageUploadMob: [action.payload, ...state.imageUploadMob],
      };
    case EMPTY_SHOWCASE_STATE:
      return { ...state, imageUploadDesk: action.payload };
    default:
      return state;
  }
}

export default productShowcaseReducer;
