import { RepeatIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { postError } from "../../services/loggingError";
import "./styles.scss";

function FallbackErrorPage({ error, resetErrorBoundary }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onHandleBackToHome = () => {
    window.location.pathname = "/";
  };

  const onHandleRefresh = () => {
    resetErrorBoundary();
  };

  const onHandleClearCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      onHandleRefresh();
    }
  };

  const handleOpenModal = () => {
    postError(error);
    onOpen();
  };

  return (
    <>
      <section className="p-0 error-container">
        <Container>
          <Row>
            <Col sm="12">
              <div className="error-section">
                <div style={{ position: "relative" }}>
                  <h1>500</h1>
                  <div className="error-section-title">
                    <Text color="#015352">Yaah</Text>
                  </div>
                </div>
                <Text className="error-section-subtitle">
                  Halaman gagal dimuat
                </Text>
                <Text>
                  Sepertinya terjadi sesuatu, Anda bisa coba lakukan beberapa
                  hal di bawah ini:
                </Text>

                <Center>
                  <Flex direction="column">
                    <Box>
                      1. Muat ulang Halaman{" "}
                      <Button
                        className="button-text"
                        colorScheme="teal"
                        backgroundColor="#015352"
                        m={3}
                        onClick={onHandleRefresh}
                      >
                        <RepeatIcon />
                        &nbsp; Muat ulang
                      </Button>
                    </Box>
                    <Box>
                      2. Hapus cache browser{" "}
                      <Button
                        className="button-text"
                        colorScheme="teal"
                        backgroundColor="#015352"
                        m={3}
                        onClick={onHandleClearCache}
                      >
                        Hapus
                      </Button>
                    </Box>
                  </Flex>
                </Center>

                <Text>
                  Jika masih terjadi error, silahkan laporkan dan tunggu tim
                  kami menyelesaikannya.
                </Text>
                <Center>
                  <Button
                    onClick={handleOpenModal}
                    colorScheme="teal"
                    variant="outline"
                    m={3}
                    textDecoration="none"
                    className="button-text"
                  >
                    Laporkan masalah
                  </Button>
                </Center>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex direction="column">
              <Heading as="h4" size="md">
                Terima kasih sudah melaporkan
              </Heading>
              <Text>
                Tim kami akan segera menyelesaikan masalah yang terjadi. Anda
                bisa kembali ke Home.
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              w="100%"
              colorScheme="teal"
              backgroundColor="#015352"
              mr={3}
              onClick={onHandleBackToHome}
            >
              Kembali ke Home
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FallbackErrorPage;
