import { SET_URBAN } from "../actionTypes";

const initialState = {
  urbans: [],
};

function urbanReducer(state = initialState, action) {
  switch(action.type) {
    case SET_URBAN:
      return { ...state, urbans: action.payload };
    default:
      return state;
  }
}

export default urbanReducer;
