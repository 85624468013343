import { SET_SHOWPOPUP } from "../actionTypes";

const initialState = {
  popup: false,
};

function stockReducer(state = initialState, action) {
  if (action.type === SET_SHOWPOPUP) {
    return { ...state, popup: action.payload };
  }
  return state;
}

export default stockReducer;
